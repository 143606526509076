<template>
  <div>
    <h4>Simple and safe tool to locally convert HEIC to JPG</h4>
    <a-spin :spinning="loading" tip="Converting...">
      <a-upload-dragger
        name="file"
        :multiple="true"
        :before-upload="() => false"
        @change="convertFile"
        :file-list="fileList"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p class="ant-upload-hint">
          Support for a single or bulk upload.
        </p>
      </a-upload-dragger>
    </a-spin>
  </div>
</template>

<script>
import heic2any from 'heic2any'

export default {
  data() {
    return {
      loading: false,
      fileList: [],
    }
  },
  methods: {
    async convertFile(event) {
      console.log('convertFile called with ', event.fileList)
      // Ant Design's event provides fileList in the event object directly
      const files = event.fileList
        .map(fileItem => fileItem.originFileObj) // Get the raw file object

      // If no file is fully uploaded, exit early
      if (files.length === 0) {
        return
      }
      // Start loading state
      this.loading = true
      try {
        // Process each file
        for (const heicFile of files) {
          // Check if file is a HEIC file
          if (!/\.(heic)$/i.test(heicFile.name)) {
            alert('Only HEIC files are supported. Please upload a valid file.')
            continue // Skip to the next file
          }
          // Convert HEIC to JPEG using heic2any
          const jpegBlob = await heic2any({
            blob: heicFile,
            toType: 'image/jpeg',
            quality: 0.8,
          })

          // Create a link element
          const link = document.createElement('a')
          link.href = URL.createObjectURL(jpegBlob)

          // Use the original file name, but change extension to .jpeg
          link.download = heicFile.name.replace(/\..+$/, '.jpeg')

          // Trigger download and clean up
          link.click()
          URL.revokeObjectURL(link.href)
        }
      } catch (error) {
        console.error('Conversion Failed: ', error)
        alert('Conversion failed. Please try again.')
      } finally {
        // End loading state
        this.loading = false
        this.fileList = []
      }
    },
  },
}
</script>

<style>

</style>
